import InnerPageBanner from "../../components/InnerPageBanner";

function MicrosoftDynamics() {
  const title = "Microsoft Dynamics 365";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  Microsoft Dynamics 365 is a powerful suite of applications
                  designed to help businesses streamline operations, improve
                  customer engagement, and drive digital transformation. At
                  Seven83 Systems, we specialize in implementing, customizing,
                  and optimizing Microsoft Dynamics 365 to meet your unique
                  business needs.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="whychoose-us-item h-100" data-aos="flip-left">
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Certified Dynamics 365 Experts</a>
                      </h3>
                      <p>
                        Our team of certified Dynamics 365 professionals ensures
                        successful implementation and customization tailored to
                        your business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="300"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">CRM and ERP Solutions</a>
                      </h3>
                      <p>
                        We offer end-to-end services for both CRM and ERP
                        solutions within Dynamics 365, ensuring complete
                        coverage.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="600"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Customization at Scale</a>
                      </h3>
                      <p>
                        We configure Dynamics 365 to match your exact business
                        processes, ensuring maximum usability and efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="900"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Seamless Integration</a>
                      </h3>
                      <p>
                        We integrate Dynamics 365 with your existing platforms
                        and tools, creating a unified digital ecosystem.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1200"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Industry-Specific Solutions</a>
                      </h3>
                      <p>
                        Our Dynamics 365 solutions are tailored to meet the
                        unique needs of your industry, whether in healthcare,
                        logistics, or government.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1500"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Ongoing Support</a>
                      </h3>
                      <p>
                        Beyond deployment, we provide continuous support and
                        optimization to ensure your system runs smoothly and
                        adapts to changing needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title">
                  <h2 className="title">
                    Explore
                    <br />
                    Microsoft Dynamics 365
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="services-item h-100" data-aos="e">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Dynamics 365 Overview</a>
                  </h3>
                  <p>
                    Learn about the full range of capabilities within the
                    Dynamics 365 suite.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/automation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">CRM vs ERP</a>
                  </h3>
                  <p>
                    Explore the differences between Dynamics 365 CRM and ERP
                    solutions and how they can work together for your business.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/success-story.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Customization Options</a>
                  </h3>
                  <p>
                    Discover the customization options we offer to ensure
                    Dynamics 365 aligns perfectly with your business needs.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/ai.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="900"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Industry-Specific Dynamics 365 Solutions: </a>
                  </h3>
                  <p>
                    Learn about our tailored solutions for industries such as
                    healthcare, government, and logistics.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/responsive.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="1200"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Dynamics 365 Success Stories</a>
                  </h3>
                  <p>
                    Explore case studies showing how we’ve helped businesses
                    achieve operational excellence with Dynamics 365.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/startup.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MicrosoftDynamics;
