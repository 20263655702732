import InnerPageBanner from "../../components/InnerPageBanner";

function Cloud() {
  const title = "Cloud";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  The cloud is transforming how businesses operate by providing
                  agility, scalability, and cost-efficiency. At Seven83 Systems,
                  we offer comprehensive cloud solutions designed to empower
                  your business with the flexibility it needs to innovate and
                  grow. Whether you're looking to migrate to the cloud, optimize
                  your current infrastructure, or implement multi-cloud
                  strategies, we provide expert guidance every step of the way.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="whychoose-us-item h-100" data-aos="flip-left">
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Cloud Experts</a>
                      </h3>
                      <p>
                        Our certified cloud architects ensure smooth cloud
                        migration and efficient infrastructure setup, customized
                        for your business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="300"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Multi-Cloud Solutions</a>
                      </h3>
                      <p>
                        We offer flexible, multi-cloud strategies, allowing you
                        to leverage the strengths of different platforms.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="600"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Scalability on Demand</a>
                      </h3>
                      <p>
                        Our cloud services are designed to scale with your
                        business, providing resources when you need them most.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="900"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Cost Optimization</a>
                      </h3>
                      <p>
                        We optimize your cloud setup to reduce unnecessary
                        costs, ensuring you only pay for the services you use.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1200"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Security Focused</a>
                      </h3>
                      <p>
                        We implement industry-leading cloud security protocols
                        to protect your data and ensure compliance with
                        regulations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1500"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Ongoing Support</a>
                      </h3>
                      <p>
                        We provide continuous monitoring and support to ensure
                        your cloud environment runs smoothly and efficiently.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title">
                  <h2 className="title">
                    Explore
                    <br />
                    Cloud
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="services-item h-100" data-aos="fade-left">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cloud Migration Process</a>
                  </h3>
                  <p>
                    Discover our structured approach to migrating your business
                    to the cloud.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/cloud-service.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cloud Providers We Support</a>
                  </h3>
                  <p>
                    Learn about the major cloud platforms we work with,
                    including AWS, Azure, and Google Cloud.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/remote-access.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Benefits of Cloud</a>
                  </h3>
                  <p>
                    Explore how cloud solutions can enhance flexibility, reduce
                    costs, and boost efficiency in your business.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/data-transformation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="900"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Security in the Cloud</a>
                  </h3>
                  <p>
                    Understand the security measures we take to protect your
                    business’s cloud infrastructure.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/encrypted.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="1200"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Hybrid & Multi-Cloud Solutions</a>
                  </h3>
                  <p>
                    Learn how we combine different cloud environments to create
                    the best solution for your business.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/cloud-network.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cloud;
