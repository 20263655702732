import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import { ChevronDown, CircleUserRound } from "lucide-react";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";

const menuItems = [
  {
    label: "Add Category",
    url: "/addCategory",
    subMenu: [],
  },
  {
    label: "Post News",
    url: "/addPost",
    subMenu: [],
  },
  {
    label: "Post Job",
    url: "/postJob",
    subMenu: [],
  },
];

function AdminMainNavbar() {
  const [showPopover, setShowPopover] = useState(false);
  const popoverBottom = (
    <Popover id="popover-positioned-bottom" className="popover-header">
      <div
        className="btn mb-2"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#profilePage"
      >
        Profile
      </div>
      <div className="btn mb-1">Logout </div>
    </Popover>
  );
  return (
    <>
      {/* <!-- Header Start  --> */}
      <div
        id="header"
        className="section header-section header-section-menu sticky navbar-fixed-top "
      >
        <div className="container-fluid px-lg-5 fixed bg-secondary">
          {/* <!-- Header Wrap Start  --> */}
          <div className="header-wrap-admin">
            <div className="header-logo">
              <a className="logo-white" href="/">
                <img src="/img/Seven83_white_logo.svg" alt="" />
              </a>
            </div>
            <div className="header-menu d-none d-lg-block ms-auto">
              <ul className="main-menu">
                {menuItems.map((menuItem, index) => (
                  <li
                    key={index}
                    className={menuItem.subMenu.length ? "has-submenu" : ""}
                  >
                    <NavLink className="nav-link" to={menuItem.url}>
                      {menuItem.label}
                      {menuItem.subMenu.length > 0 && <ChevronDown size="22" />}
                    </NavLink>
                    {/* {menuItem.subMenu.length > 0 && ( */}
                    {/* {menuItem.subMenu.length > 0 && (
                      <ul className="sub-menu">
                        {menuItem.subMenu.map((subMenuItem, subIndex) => (
                          <li key={subIndex}>
                            <NavLink className="nav-link" to={subMenuItem.url}>
                              {subMenuItem.label}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )} */}
                  </li>
                ))}
              </ul>
            </div>
            {/* <!-- Header Meta Start --> */}
            <div className="header-meta">
              {/* <!-- Header Search Start --> */}
              <div className="header-search">
                <span className="search-btn" type="button" href="#">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popoverBottom}
                    show={showPopover}
                    rootClose
                    onToggle={() => setShowPopover(!showPopover)}
                  >
                    <span onClick={() => setShowPopover(!showPopover)}>
                      <CircleUserRound
                        id="search-close"
                        className="flaticon-close search-close"
                      />{" "}
                    </span>
                  </OverlayTrigger>
                </span>
                <div className="search-wrap">
                  <div className="search-inner">
                    <CircleUserRound
                      id="search-close"
                      className="flaticon-close search-close"
                    />
                    <div className="search-cell">
                      <form action="#">
                        <div className="search-field-holder">
                          <input
                            className="main-search-input"
                            type="search"
                            placeholder="Search Your Keyword..."
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Header Search End --> */}

              {/* <!-- Header Toggle Start --> */}
              <div className="header-toggle d-lg-none">
                <button
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              {/* <!-- Header Toggle End --> */}
            </div>
            {/* <!-- Header Meta End  --> */}
          </div>
          {/* <!-- Header Wrap End  --> */}
        </div>
      </div>
      {/* <!-- Header End --> */}

      <div className="offcanvas offcanvas-start" id="offcanvasExample">
        <div className="offcanvas-header">
          <div className="offcanvas-logo">
            <a className="logo-white" href="../index.html">
              <img src="/img/Seven83_white_logo.svg" alt="" />
            </a>
          </div>
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="offcanvas"
          >
            <i className="flaticon-close"></i>
          </button>
        </div>

        <div className="offcanvas-body">
          <div className="offcanvas-menu">
            <ul className="main-menu">
              {menuItems.map((menuItem, index) => (
                <li
                  key={index}
                  className={menuItem.subMenu.length ? "has-submenu" : ""}
                >
                  <NavLink className="nav-link" to={menuItem.url}>
                    {menuItem.label}
                    {menuItem.subMenu.length > 0 && <ChevronDown size="22" />}
                  </NavLink>
                  {/* {menuItem.subMenu.length > 0 && ( */}
                  {menuItem.subMenu.length > 0 && (
                    <ul className="sub-menu">
                      {menuItem.subMenu.map((subMenuItem, subIndex) => (
                        <li key={subIndex}>
                          <NavLink className="nav-link" to={subMenuItem.url}>
                            {subMenuItem.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="profilePage"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="profilePageLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            <div className="modal-body">
            <div className="section-title text-center">
                <h5 className="title fs-2">Profile</h5>
              </div>
              <form>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="single-form">
                      <div className="section-title text-center">
                        <p className="title fs-5">Username</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    <div className="single-form">
                      <input type="text" placeholder="Last Name" disabled />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="single-form">
                      <div className="section-title text-center">
                        <p className="title fs-5">Passowrd</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    <div className="single-form">
                      <input type="text" placeholder="Last Name" disabled />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
            <button
                              className="btn rounded-0 ms-auto"
                              type="submit"
                              data-bs-dismiss="modal"

                            >
                              Close
                            </button>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminMainNavbar;
