import InnerPageBanner from "../../components/InnerPageBanner";

function Government() {
  const title = "Government";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  Government agencies face the challenge of adapting to the
                  rapid pace of technological change while maintaining efficient
                  operations and adhering to strict regulations. At Seven83
                  Systems, we provide tailored digital solutions to modernize
                  government operations, improve service delivery, and enhance
                  data security. Our services include cloud migration, data
                  analytics, cybersecurity, and digital transformation, helping
                  government bodies stay ahead of the curve while ensuring
                  compliance and efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="whychoose-us-item h-100" data-aos="flip-left">
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Government Experience</a>
                      </h3>
                      <p>
                        We have extensive experience working with public sector
                        organizations, understanding their unique challenges and
                        regulatory requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="300"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Data-Driven Solutions</a>
                      </h3>
                      <p>
                        Our data and analytics services enable better
                        decision-making, improving policy outcomes and service
                        delivery.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1200"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Regulatory Compliance</a>
                      </h3>
                      <p>
                        We ensure that all our solutions comply with government
                        regulations and standards, including data privacy and
                        security.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="900"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Innovative Technology</a>
                      </h3>
                      <p>
                        We integrate cutting-edge technologies like AI, cloud,
                        and automation to help governments modernize and improve
                        efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-key-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="section-title">
                <h2 className="title">
                  Key Solutions <br />
                  for Government
                </h2>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="services-card" data-aos="fade-up">
                    <div className="icon">
                      <img src="img/icons/connection.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Citizen-Centric <br /> Services
                        </h3>
                        <span>Government</span>
                      </div>
                      <p>
                        Implement digital solutions that improve accessibility
                        and enhance public service delivery.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <img src="img/icons/integration.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Cloud Migration for <br /> Governments
                        </h3>
                        <span>Government</span>
                      </div>
                      <p>
                        Move legacy systems to the cloud for better scalability,
                        security, and cost efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="icon">
                      <img src="img/icons/security-system.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>Secure Government Operations</h3>
                        <span>Government</span>
                      </div>
                      <p>
                        Protect sensitive data with advanced cybersecurity
                        measures and compliance solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="900"
                  >
                    <div className="icon">
                      <img src="img/icons/process.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Operational <br /> Efficiency
                        </h3>
                        <span>Government</span>
                      </div>
                      <p>
                        Streamline government workflows through process
                        automation and digital transformation initiatives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title">
                  <h2 className="title">
                    Explore
                    <br />
                    Governments
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6">
              <div className="services-item h-100" data-aos="fade-left">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Digital Transformation for Governments</a>
                  </h3>
                  <p>
                    Learn how we help governments modernize their operations and
                    deliver better services.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/automation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cloud Adoption</a>
                  </h3>
                  <p>
                    Discover the benefits of cloud migration for government
                    agencies, including improved scalability and security.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/ai.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="900"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Data-Driven Decision Making</a>
                  </h3>
                  <p>
                    Explore how our analytics solutions enable government
                    organizations to make informed decisions.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/responsive.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="1200"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Security & Compliance</a>
                  </h3>
                  <p>
                    Understand how we protect sensitive government data and
                    ensure regulatory compliance with our cybersecurity
                    solutions.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/startup.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Government;
