import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { BriefcaseBusiness, GraduationCap, MapPin, Search } from "lucide-react";
import InnerPageBanner from "../components/InnerPageBanner";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Careers() {
  const title = "Careers";
  const bannerImage = "img/aboutUsBanner.jpg";
  const [careers, setCareers] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [filteredCareers, setFilteredCareers] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleApplyClick = (jobId, jobTitle) => {
    navigate("/applyForm", { state: { jobId, jobTitle } });
  };

  const onSubmit = (data) => {
    const filtered = careers.filter((career) => {
      const matchesLocation = data.location
        ? career.location === data.location
        : true;
      const matchesJobType = data.job_type
        ? career.job_type === data.job_type
        : true;
      const matchesExperience = data.experience
        ? career.experience.includes(data.experience)
        : true;
      return matchesLocation && matchesJobType && matchesExperience;
    });
    setFilteredCareers(filtered);
  };

  useEffect(() => {
    const fetchCareers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/careers/`
        );
        setCareers(response?.data || []);
        setFilteredCareers(response?.data || []);
      } catch (error) {
        console.error("Error fetching Careers:", error);
      }
    };
    fetchCareers();
  }, []);

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section section-careers">
        <div className="container">
          <div className="card-body py-5">
            <div className="section-title">
              <h2 className="title">Job Openings</h2>
            </div>
            <div className="my-4 content_details">
              <Search className="search-icon text-primary" />
              <input
                type="text"
                className="form-control form-search-career"
                placeholder="Search Jobs"
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12 mb-3">
                  <select
                    className={`form-select form-select-career rounded-0`}
                    {...register("location", {
                      required: "Location is required",
                    })}
                  >
                    <option value="" disabled selected hidden>
                      Select Location
                    </option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Warangal">Warangal</option>
                    <option value="Tamilnadu">Tamilnadu</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Vishakapatnam">Vishakapatnam</option>
                  </select>
                  {errors.location && (
                    <p className="text-danger">{errors.location.message}</p>
                  )}
                </div>

                <div className="col-lg-4 col-md-6 col-12 mb-3">
                  <input
                    type="text"
                    className={`form-control form-input-career rounded-0 `}
                    {...register("experience", {
                      required: "Experience is required",
                      minLength: {
                        value: 1,
                        message: "Please enter a valid experience",
                      },
                    })}
                    placeholder="Experience"
                  />
                  {errors.experience && (
                    <p className="text-danger">{errors.experience.message}</p>
                  )}
                </div>

                <div className="col-lg-4 col-md-6 col-12 mb-3">
                  <select
                    className={`form-select form-select-career rounded-0`}
                    {...register("job_type", {
                      required: "Job type is required",
                    })}
                  >
                    <option value="" disabled selected hidden>
                      Select Job type
                    </option>

                    <option value="Permanent">Permanent</option>
                    <option value="Temporary">Temporary</option>
                    <option value="Freelancing">Freelancing</option>
                    <option value="Intern">Intern</option>
                  </select>
                  {errors.job_type && (
                    <p className="text-danger">{errors.job_type.message}</p>
                  )}
                </div>

                <div className="d-flex flex-row-reverse pt-2 mr-2">
                  <button
                    className="btn rounded-0 ms-auto applyButton"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container pb-5">
          <div className="choose-us-wrap">
            <div className="choose-us-content-wrap">
              <div className="row">
                {filteredCareers?.length > 0 ? (
                  filteredCareers.map((item, index) => (
                    <div
                      className="col-xl-4 col-lg-4 col-md-6"
                      key={item.title}
                    >
                      <div
                        className="choose-us-item-02"
                        data-aos="flip-right"
                        data-aos-delay={`${index * 300}`}
                      >
                        <div>
                          <h3 className="title">
                            <a href="#">{item.title}</a>
                          </h3>
                          <div className="mb-3">
                            <p>
                              <GraduationCap
                                size={18}
                                className="me-2 mb-1 text-primary "
                              />
                              {item.experience}
                            </p>
                            <p>
                              <MapPin
                                size={18}
                                className="me-2 mb-1 text-primary "
                              />
                              {item.location}
                            </p>

                            <p>
                              <BriefcaseBusiness
                                size={18}
                                className="me-2 mb-1 text-primary "
                              />
                              {item.job_type}
                            </p>
                          </div>
                          <div className="form-btn d-flex">
                            <div>
                              <button
                                type="button"
                                className="btn btn-outline-secondary bg-transparent border-1 rounded-0"
                                onClick={() => {
                                  setSelectedJob(item);
                                  setModalShow(true);
                                }}
                              >
                                Info
                              </button>
                            </div>
                            <button
                              className="btn rounded-0 ms-auto"
                              type="submit"
                              onClick={() =>
                                handleApplyClick(item.id, item.title)
                              }
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card rounded-0 border-0 section-careers">
                    <div className="card-body m-5 border-0">
                      <div className="section-title">
                        <p className="title text-primary">
                          No job openings found for the selected criteria.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedJob && (
        <VerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          job={selectedJob}
        />
      )}
    </>
  );

  function VerticallyCenteredModal({ job, ...props }) {
    return (
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="job_content">
          <h3 className="title">{job?.title}</h3>
          <div className="d-flex gap-4 pt-2">
            <p>
              <GraduationCap size={20} className="me-2 mb-1 text-primary" />
              {job.experience}
            </p>
            <p>
              <MapPin size={20} className="me-2 mb-1 text-primary" />
              {job.location}
            </p>
            <p>
              <BriefcaseBusiness size={20} className="me-2 mb-1 text-primary" />
              {job.job_type}
            </p>
          </div>
          <h4 className="pt-2 title text-primary">Job Description:</h4>
          <div className="blog-content">
            <div
              dangerouslySetInnerHTML={{
                __html: job?.job_description || "",
              }}
              className="blog-content-text"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={props.onHide}
            className="btn rounded-0 ms-auto applyButton"
            type="submit"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Careers;
