import InnerPageBanner from "../../components/InnerPageBanner";

function EnterpriseIntegration() {
  const title = "Enterprise Integration";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  Enterprise Integration is essential in today’s fast-paced
                  digital landscape, where businesses rely on multiple systems
                  to run smoothly. At Seven83 Systems, we specialize in
                  integrating complex enterprise systems, ensuring seamless
                  communication and data flow between them. Whether it's CRM,
                  ERP, or custom-built applications, we ensure your systems work
                  together, reducing manual processes and improving operational
                  efficiency.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="whychoose-us-item h-100" data-aos="flip-left">
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">End-to-End Integration</a>
                      </h3>
                      <p>
                        We provide comprehensive integration services, from
                        planning and design to implementation and support.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="300"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Cross-Platform Expertise</a>
                      </h3>
                      <p>
                        We integrate a wide range of platforms, including CRM,
                        ERP, cloud, and legacy systems.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="600"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Custom Integration Solutions</a>
                      </h3>
                      <p>
                        We develop tailored integration solutions that meet your
                        specific business and operational requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="900"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Improved Efficiency</a>
                      </h3>
                      <p>
                        Our solutions eliminate data silos and manual workflows,
                        ensuring a streamlined, automated environment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1200"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Scalable and Future-Proof</a>
                      </h3>
                      <p>
                        We design integration solutions that scale with your
                        business and adapt to new technologies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1500"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Security and Compliance</a>
                      </h3>
                      <p>
                        Our integrations are designed with security in mind,
                        ensuring compliance with industry standards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title">
                  <h2 className="title">
                    Explore
                    <br />
                    Enterprise Integration
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="services-item h-100" data-aos="fade-left">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Our Integration Process</a>
                  </h3>
                  <p>
                    Learn how we approach system integration to ensure seamless
                    connectivity between platforms.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/automation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">API Integration</a>
                  </h3>
                  <p>
                    Discover how API-based integration can enable real-time data
                    sharing and enhanced system interoperability.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/success-story.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Legacy System Integration</a>
                  </h3>
                  <p>
                    Explore how we modernize your legacy systems by integrating
                    them with modern platforms
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/ai.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="900"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Middleware Solutions</a>
                  </h3>
                  <p>
                    Learn about the middleware tools we use to enable effective
                    communication between systems.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/responsive.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="1200"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Success Stories</a>
                  </h3>
                  <p>
                    Read case studies of businesses that have benefited from our
                    enterprise integration services.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/startup.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnterpriseIntegration;
