import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast, Toaster } from "sonner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Card } from "react-bootstrap";

function JobPost() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("location", data.location);
    formData.append("experience", data.experience);
    formData.append("job_type", data.job_type);
    formData.append("job_description", data.job_description);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/careers/`,
        formData
      );
      console.log("Form submitted successfully:", response);
      toast.success("Posting successfully completed.");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Posting Error");
    }
  };

  return (
    <div className="section seven-about-section-03 section-padding">
      <div className="section-title text-center mt-3">
        <h2 className="title">Post Job</h2>
      </div>
      <div className="container pt-3">
        <Card className="col-sm-6 mx-auto">
          <div className="general-inquiry-page m-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="single-form">
                    <input
                      className="text-muted"
                      type="text"
                      {...register("title", { required: "Title is required" })}
                      placeholder="Title *"
                    />
                    {errors.title && (
                      <span className="text-danger">
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="single-form">
                    <input
                      className="text-muted"
                      type="text"
                      {...register("location", {
                        required: "Location is required",
                      })}
                      placeholder="Location *"
                    />
                    {errors.location && (
                      <span className="text-danger">
                        {errors.location.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="single-form">
                    <input
                      className="text-muted"
                      type="text"
                      {...register("experience", {
                        required: "Experience is required",
                      })}
                      placeholder="Experience *"
                    />
                    {errors.experience && (
                      <span className="text-danger">
                        {errors.experience.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="single-form">
                    <select
                      className="form-select text-muted"
                      {...register("job_type", {
                        required: "Job type is required",
                      })}
                    >
                      <option value="" disabled selected hidden>
                        Select Job type
                      </option>
                      <option id="1" value="Permanent">
                        Permanent
                      </option>
                      <option id="2" value="Temporary">
                        Temporary
                      </option>
                      <option id="3" value="Freelancing">
                        Freelancing
                      </option>
                      <option id="3" value="Intern">
                        Intern
                      </option>
                    </select>
                    {errors.job_type && (
                      <span className="text-danger">
                        {errors.job_type.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="single-form">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "blockQuote",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "insertTable",
                          "mediaEmbed",
                          "imageUpload",
                          "undo",
                          "redo",
                          "|",
                          "alignment:left",
                          "alignment:right",
                          "alignment:center",
                          "alignment:justify",
                        ],
                        image: {
                          toolbar: [
                            "imageTextAlternative",
                            "imageStyle:full",
                            "imageStyle:side",
                          ],
                          upload: {
                            types: ["jpeg", "png", "gif", "bmp", "webp"],
                          },
                        },
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue("job_description", data);
                      }}
                    />
                    {errors.job_description && (
                      <span className="text-danger">
                        {errors.job_description.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12 pt-4">
                  <div className="form-btn d-flex">
                    <button className="btn rounded-0 ms-auto" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <Toaster position="bottom-right" richColors />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default JobPost;
