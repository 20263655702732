import InnerPageBanner from "../../components/InnerPageBanner";

function Telecommunications() {
  const title = "Telecommunications";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  The telecommunications industry is at the forefront of the
                  digital revolution, driven by the increasing demand for
                  connectivity, 5G technology, and data services. At Seven83
                  Systems, we offer tailored technology solutions to help
                  telecom providers enhance network performance, improve
                  customer experiences, and stay ahead of technological
                  advances. Our services include network optimization, cloud
                  infrastructure, AI-based analytics, and cybersecurity
                  solutions designed to support the telecom industry’s unique
                  needs.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="whychoose-us-item h-100" data-aos="flip-left">
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Telecom Expertise</a>
                      </h3>
                      <p>
                        We have a deep understanding of the telecommunications
                        industry, allowing us to provide solutions that meet its
                        unique challenges.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="300"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Network Optimization</a>
                      </h3>
                      <p>
                        Our solutions enhance network performance, ensuring
                        faster speeds and better reliability for customers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1200"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Scalable Solutions</a>
                      </h3>
                      <p>
                        We offer scalable solutions that support telecom
                        providers’ growth, from infrastructure optimization to
                        5G deployment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="900"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Data-Driven Insights</a>
                      </h3>
                      <p>
                        Our AI-based analytics solutions provide telecom
                        providers with valuable insights into customer behavior
                        and network performance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-key-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="section-title">
                <h2 className="title">
                  Key Solutions <br />
                  for Telecommunications
                </h2>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="services-card" data-aos="fade-up">
                    <div className="icon">
                      <img src="img/icons/connection.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>5G Network Deployment</h3>
                        <span>Telecommunications</span>
                      </div>
                      <p>
                        Enable faster, more reliable connectivity with our
                        solutions for 5G network planning and deployment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <img src="img/icons/integration.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          AI-Powered Customer <br /> Insights
                        </h3>
                        <span>Telecommunications</span>
                      </div>
                      <p>
                        Use data analytics to understand customer behavior and
                        improve service delivery.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="icon">
                      <img src="img/icons/security-system.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>Cloud-Based Telecom Solutions</h3>
                        <span>Telecommunications</span>
                      </div>
                      <p>
                        Migrate telecom operations to the cloud for enhanced
                        scalability and reduced costs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="900"
                  >
                    <div className="icon">
                      <img src="img/icons/process.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>Network Security</h3>
                        <span>Telecommunications</span>
                      </div>
                      <p>
                        Protect telecom networks from cyber threats with our
                        advanced cybersecurity solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title pr-4">
                  <h2 className="title">
                    Explore
                    <br />
                    <span className="fs-1">Telecommunications</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6">
              <div className="services-item h-100" data-aos="fade-left">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">5G and Beyond</a>
                  </h3>
                  <p>
                    Learn how we help telecom providers prepare for the next
                    generation of network technology, including 5G deployment.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/automation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Network Performance Optimization: </a>
                  </h3>
                  <p>
                    Discover how we optimize telecom networks to improve
                    performance and reliability.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/ai.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="900"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">AI for Telecommunications</a>
                  </h3>
                  <p>
                    Explore how AI and data analytics can help telecom providers
                    enhance customer experiences and optimize operations.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/responsive.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="1200"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cloud Infrastructure for Telecoms</a>
                  </h3>
                  <p>
                    Understand how cloud solutions can improve telecom
                    infrastructure scalability and reduce costs.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/startup.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Telecommunications;
