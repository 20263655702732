import React, { useRef, useState } from "react";

function InnerPageBanner({ image, title }) {
  return (
    <>
      <div
        className="section page-banner-section"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="shape-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="700"
            height="894.281"
            viewBox="0 0 944.285 894.281"
          >
            <path
              id="Path_500"
              data-name="Path 500"
              d="M39.612,410.76,467.344,29.824c49.166-43.3,123.294-39.754,166.594,9.789L914.192,317.344a117.979,117.979,0,0,1-9.79,166.594L476.671,864.191c-48.707,43.3-123.295,38.918-165.911-9.789L29.823,576.67C-13.477,527.963-9.94,453.376,39.612,410.76Z"
              transform="translate(0.281 0.277)"
              fill="#0a2e34"
            />
          </svg>
        </div>
        <div className="shape-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="726.5px"
            height="726.5px"
          >
            <path
              fillRule="evenodd"
              stroke="rgb(255, 255, 255)"
              strokeWidth="1px"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              opacity="0.302"
              fill="none"
              d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z"
            />
          </svg>
        </div>
        <div className="shape-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="515.009"
            height="515.008"
            viewBox="0 0 515.009 515.008"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="0.249"
                y1="0.91"
                x2="0.928"
                y2="-0.033"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#0a2e34" />
                <stop offset="1" stopColor="#51bb7e" />
              </linearGradient>
            </defs>
            <path
              id="Path_502"
              data-name="Path 502"
              d="M19.529,202.28l211-187.582a58.216,58.216,0,0,1,82.183,4.83l187.581,211a58.212,58.212,0,0,1-4.829,82.182l-211,187.582a58.213,58.213,0,0,1-82.182-4.83L14.7,284.462A58.213,58.213,0,0,1,19.529,202.28Z"
              transform="translate(0.007 0.007)"
              fill="url(#linear-gradient)"
            />
          </svg>
        </div>
        <div className="shape-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="972.5px"
            height="970.5px"
          >
            <path
              fillRule="evenodd"
              stroke="rgb(255, 255, 255)"
              strokeWidth="1px"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              fill="none"
              d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z"
            />
          </svg>
        </div>
        <div className="container">
          <div className="page-banner-wrap">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- Page Banner Content Start --> */}
                <div className="page-banner text-center">
                  <h2 className="title">{title}</h2>
                  <ul className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {title}
                    </li>
                  </ul>
                </div>
                {/* <!-- Page Banner Content End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InnerPageBanner;
