import InnerPageBanner from "../../components/InnerPageBanner";

function BankingFinancialInsurance() {
  const title = "Banking, Financial & Insurance";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  The banking, financial, and insurance sectors are facing
                  unprecedented change as they navigate digital transformation,
                  regulatory compliance, and evolving customer expectations.
                  Seven83 Systems provides technology solutions that help
                  financial institutions enhance customer experiences,
                  streamline operations, and ensure regulatory compliance. Our
                  services include AI-powered analytics, cloud infrastructure,
                  cybersecurity, and automation solutions, all designed to
                  support the unique challenges of the financial industry.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="whychoose-us-item h-100" data-aos="flip-left">
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Financial Expertise: </a>
                      </h3>
                      <p>
                        Our deep knowledge of the financial industry allows us
                        to deliver solutions that address its unique challenges.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="300"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Customer-Centric Solutions</a>
                      </h3>
                      <p>
                        We help financial institutions improve customer
                        experiences with AI, automation, and advanced analytics.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1200"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Regulatory Compliance</a>
                      </h3>
                      <p>
                        Our solutions ensure full compliance with financial
                        regulations, including data security and privacy.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="900"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Operational Efficiency</a>
                      </h3>
                      <p>
                        We provide solutions that streamline operations, from
                        automating claims processing to optimizing customer
                        service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-key-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="section-title">
                <h2 className="title">
                  Key Solutions <br />
                  for Banking, Financial & Insurance
                </h2>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="services-card" data-aos="fade-up">
                    <div className="icon">
                      <img src="img/icons/connection.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          AI-Powered Financial <br /> Insights
                        </h3>
                        <span>Banking, Financial & Insurance</span>
                      </div>
                      <p>
                        Use AI and data analytics to improve decision-making and
                        customer insights in the financial sector.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <img src="img/icons/integration.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Cloud-Based Financial
                          <br /> Platforms
                        </h3>
                        <span>Banking, Financial & Insurance</span>
                      </div>
                      <p>
                        Migrate financial operations to the cloud for better
                        scalability, security, and cost-efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="icon">
                      <img src="img/icons/security-system.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>Automated Insurance Claims</h3>
                        <span>Banking, Financial & Insurance</span>
                      </div>
                      <p>
                        Implement automated claims processing to reduce handling
                        times and improve customer satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="900"
                  >
                    <div className="icon">
                      <img src="img/icons/process.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Cybersecurity for Financial <br /> Institutions
                        </h3>
                        <span>Banking, Financial & Insurance</span>
                      </div>
                      <p>
                        Protect sensitive financial data with our advanced
                        cybersecurity solutions, ensuring compliance with
                        industry regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title">
                  <h2 className="title">
                    Explore
                    <br />
                    Banking, Financial & Insurances
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6">
              <div className="services-item h-100" data-aos="fade-left">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">AI in Financial Services</a>
                  </h3>
                  <p>
                    Learn how we use AI and data analytics to help financial
                    institutions improve decision-making and customer
                    experiences.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/automation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cloud for Financial Services</a>
                  </h3>
                  <p>
                    Explore how cloud solutions can enhance operational
                    efficiency and reduce costs in banking and insurance.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/ai.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="900"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cybersecurity for Financial Data</a>
                  </h3>
                  <p>
                    Discover how our cybersecurity solutions protect financial
                    data from cyber threats and ensure regulatory compliance.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/responsive.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="1200"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Automation in Insurance</a>
                  </h3>
                  <p>
                    Understand how we automate insurance processes, from claims
                    handling to policy management, to improve efficiency.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/startup.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BankingFinancialInsurance;
