import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronLeft, ChevronRight, ArrowUpRight } from "lucide-react";

import { Pagination, Navigation } from "swiper/modules";

function HomeWhyChooseSection() {
  const [swiperRef, setSwiperRef] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      <div className="section seven-about-section-04 whychoose-section section-padding">
        {/* <!-- whychoose Start --> */}
        <div className="row m-0">
          <div className="col-md-12 col-lg-4 mb-5 mb-sm-0 justify-content-center align-content-center">
            <div className="whychoose-section-text">
              <div className="section-title mb-5">
                <h3 className="sub-title">Why Choose?</h3>
                <h2 className="title">Experience the Difference</h2>
                <p className="contnet pe-5">
                  Immerse yourself in unmatched quality and cutting-edge
                  innovation. We strive to deliver an experience that sets new
                  standards and elevates expectations.
                </p>
              </div>
              <div id="whyChoose-nav" className="whyChoose-nav">
                <button
                  ref={prevRef}
                  className="btn btn-prev me-1"
                  data-controls="prev"
                >
                  <ChevronLeft />
                </button>
                <button
                  ref={nextRef}
                  className="btn btn-next"
                  data-controls="next"
                >
                  <ChevronRight />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-8 mb-0 mt-md-2">
            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={3}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              centeredSlides={false}
              spaceBetween={20}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              modules={[Pagination, Navigation]}
              className="whyChoose-slider"
            >
              <SwiperSlide className="whyChoose-slider-item">
                <div className="whyChoose-slider-inner-top">
                  <a href="#" className="btn btn-primary">
                    <ArrowUpRight />
                  </a>
                </div>

                <div className="whyChoose-slider-img">
                  <img src="/img/expertise.jpg" alt="" />
                </div>
                <div className="whyChoose-slider-text">
                  <h3>
                    <a href="#">Expertise</a>
                  </h3>
                  <span>
                    Our team of seasoned professionals brings years of
                    experience and expertise across various industries
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="whyChoose-slider-item">
                <div className="whyChoose-slider-inner-top">
                  <a href="#" className="btn btn-primary">
                    <ArrowUpRight />
                  </a>
                </div>

                <div className="whyChoose-slider-img">
                  <img src="img/innovation.jpg" alt="" />
                </div>
                <div className="whyChoose-slider-text">
                  <h3>
                    <a href="#">Innovation</a>
                  </h3>
                  <span>
                    We are committed to staying ahead of the curve and embracing
                    the latest technologies.
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="whyChoose-slider-item">
                <div className="whyChoose-slider-inner-top">
                  <a href="#" className="btn btn-primary">
                    <ArrowUpRight />
                  </a>
                </div>

                <div className="whyChoose-slider-img">
                  <img src="img/Client-Centric.jpg" alt="" />
                </div>
                <div className="whyChoose-slider-text">
                  <h3>
                    <a href="#">Client-Centric Approach</a>
                  </h3>
                  <span>
                    Your success is our success. We take the time to understand
                    your unique challenges, goals and objectives.
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide className="whyChoose-slider-item">
                <div className="whyChoose-slider-inner-top">
                  <a href="#" className="btn btn-primary">
                    <ArrowUpRight />
                  </a>
                </div>

                <div className="whyChoose-slider-img">
                  <img src="img/Results-Driven.jpg" alt="" />
                </div>
                <div className="whyChoose-slider-text">
                  <h3>
                    <a href="#">Results-Driven</a>
                  </h3>
                  <span>
                    We are results-driven and outcome focused Whether
                    <br />
                    it's improving operational efficiency.
                  </span>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeWhyChooseSection;
