import InnerPageBanner from "../components/InnerPageBanner";
import { MapPin, PhoneCall, MailOpen } from "lucide-react";
import SalesInquiryForm from "./salesInquiryForm";
import GeneralInquiryForm from "./generalInquiryForm";

function ContactUs() {
  const title = "Contact Us";
  const bannerImage = "img/ModernizeBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section contact-info-section seven-contact-section seven-contact-section-02 section-padding-02">
        <div className="container">
          <div className="contact-info-wrap">
            <div className="row">
              <div className="col-lg-5">
                <h1 className="border-bottom mb-4 fw-medium">
                  Corporate Offices
                </h1>
                <div className="single-contact-info mt-0" data-aos="fade-right">
                  <div className="info-content">
                    <h5 className="title text-primary">India</h5>
                    <h5 className="sub-title">Seven83 Systems Pvt.Ltd.</h5>
                    <div className="widget-info">
                      <ul>
                        <li className="align-items-start">
                          <div className="info-icon">
                            <MapPin />
                          </div>
                          <div className="info-text text-dark fw-normal">
                            <span>
                              Plot No 14/2, Dwaraka Park View, <br />
                              1st Floor,Sector-1, HUDA Techno Enclave,
                              <br />
                              Madhapur, Hyderabad, Telangana 500081.
                            </span>
                          </div>
                        </li>
                        <li className="align-items-center">
                          <div className="info-icon">
                            <PhoneCall />
                          </div>
                          <div className="info-text mt-2 text-dark fw-light">
                            <span>
                              <a href="#">+91 9154 783 077</a>
                            </span>
                          </div>
                        </li>
                        <li className="align-items-center">
                          <div className="info-icon">
                            <MailOpen />
                          </div>
                          <div className="info-text mt-2 text-dark fw-light">
                            <span>
                              <a href="mailto:info@783systems.com">
                                info@783systems.com
                              </a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="single-contact-info"
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <div className="info-content">
                    <h5 className="title text-primary">USA</h5>
                    <h5 className="sub-title">Seven83 Systems Inc.</h5>
                    <div className="widget-info">
                      <ul>
                        <li className="align-items-start">
                          <div className="info-icon">
                            <MapPin />
                          </div>
                          <div className="info-text text-dark fw-normal">
                            <span>
                              8 The Green STE A Dover, <br />
                              DE 19901.
                            </span>
                          </div>
                        </li>
                        {/* <li>
                          <div className="info-icon">
                            <i className="flaticon-phone-call"></i>
                          </div>
                          <div className="info-text text-dark fw-light">
                            <span>
                              <a href="#">-</a>
                            </span>
                          </div>
                        </li> */}
                        <li className="align-items-center">
                          <div className="info-icon">
                            <MailOpen />
                          </div>
                          <div className="info-text mt-2 text-dark fw-light">
                            <span>
                              <a href="mailto:info@783systems.com">
                                info@783systems.com
                              </a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="single-contact-info mb-5"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  <div className="info-content">
                    <h5 className="title text-primary">UAE</h5>
                    <h5 className="sub-title">Seven83 Systems FZC LLC.</h5>
                    <div className="widget-info">
                      <ul>
                        <li className="align-items-start">
                          <div className="info-icon">
                            <MapPin />
                          </div>
                          <div className="info-text text-dark fw-normal">
                            <span>
                              Business Centre, <br /> Sharjah Publishing City
                              Free Zone,
                              <br />
                              Sharjah, United Arab Emirates.
                            </span>
                          </div>
                        </li>
                        {/* <li>
                          <div className="info-icon">
                            <i className="flaticon-phone-call"></i>
                          </div>
                          <div className="info-text text-dark fw-light">
                            <span>
                              <a href="#">-</a>
                            </span>
                          </div>
                        </li> */}
                        <li className="align-items-center">
                          <div className="info-icon">
                            <MailOpen />
                          </div>
                          <div className="info-text mt-2 text-dark fw-light">
                            <span>
                              <a href="mailto:info@783systems.com">
                                info@783systems.com
                              </a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 pb-5">
                <div className="contact-wrap h-100 ">
                  <div className="contact-form h-100">
                    <h1 className="border-bottom fw-medium">Get in Touch</h1>
                    <div className="contact-form-wrap">
                      <ul
                        className="nav mb-3 d-flex"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-General-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#pills-General"
                            type="button"
                            role="tab"
                            aria-controls="pills-General"
                            aria-selected="true"
                          >
                            General Inquiry
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-Sales-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#pills-Sales"
                            type="button"
                            role="tab"
                            aria-controls="pills-Sales"
                            aria-selected="true"
                          >
                            Sales Inquiry
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-General"
                          role="tabpanel"
                          aria-labelledby="pills-General-tab"
                        >
                          <p>
                            Fields marked with an
                            <span className="text-danger">*</span> are required
                          </p>
                          <GeneralInquiryForm />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-Sales"
                          role="tabpanel"
                          aria-labelledby="pills-Sales-tab"
                        >
                          <p>
                            Fields marked with an
                            <span className="text-danger">*</span> are required
                          </p>
                          <SalesInquiryForm />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Contact Form End --> */}
                </div>
                {/* <!-- Contact Wrap End --> */}
              </div>
            </div>
          </div>
          {/* <!-- Contact Info Wrap End --> */}
        </div>
      </div>
      {/* <!-- Contact Info End --> */}
    </>
  );
}

export default ContactUs;
