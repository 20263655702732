import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronLeft, ChevronRight, ArrowUpRight } from "lucide-react";

import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

function HomeIndustriesSection() {
  const [swiperRef, setSwiperRef] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      <div className="section seven-choose-us-section section-padding">
        <div className="container-fluid px-lg-5 px-md-2">
          {/* <!-- Choose Us Wrap Start --> */}
          <div className="choose-us-wrap">
            <div className="section-title text-left w-100 w">
              <h2 className="title">Industries</h2>
            </div>
            <div className="choose-us-content-wrap">
              <div className="swiper IndustriesSwiper">
                <Swiper
                  onSwiper={setSwiperRef}
                  slidesPerView={4}
                  spaceBetween={20}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                  loop={true}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                  }}
                  className="swiper-wrapper"
                >
                  <SwiperSlide className="swiper-slide">
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                        <Link to="./government">
                          <img src="img/Government.jpg" alt="" />
                        <div className="choose-us-content">
                          <h3 className="title">Government</h3>
                          <p>
                            Government agencies face the challenge of adapting
                            to the rapid pace of technological change while
                            maintaining efficient operations and adhering to
                            strict regulations
                          </p>
                        </div>
                        </Link>

                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                        <Link to="./healthcare">
                          <img src="img/Healthcare.jpg" alt="" />
                 <div className="choose-us-content">
                          <h3 className="title">Healthcare</h3>
                          <p>
                            The healthcare industry is undergoing a major
                            transformation, driven by the need for better
                            patient outcomes, cost efficiency, and data
                            security.
                          </p>
                        </div>
                        </Link>       
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                        <Link to="./logistics">
                          <img src="img/Logistics.jpg" alt="" />
                        <div className="choose-us-content">
                          <h3 className="title">Logistics</h3>
                          <p>
                            In the fast-paced world of logistics, businesses are
                            constantly striving to optimize supply chains,
                            reduce operational costs, and increase efficiency
                          </p>
                        </div>                        </Link>       


                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                      <Link to="./manufacturing">
                        <img src="img/Manufacturing.jpg" alt="" />
                        <div className="choose-us-content">
                          <h3 className="title">Manufacturing</h3>
                          <p>
                            Manufacturing is evolving rapidly with the rise of
                            Industry 4.0, which brings automation, IoT, and
                            advanced analytics into the production process.
                          </p>
                        </div>
                        </Link>

                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                        <Link to="./telecommunications">
                          <img src="img/Telecommunications.jpg" alt="" />
                        
                        <div className="choose-us-content">
                          <h3 className="title">Telecommunications</h3>
                          <p>
                            The telecommunications industry is at the forefront
                            of the digital revolution, driven by the increasing
                            demand for connectivity, 5G technology, and data
                            services.
                          </p>
                        </div>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="choose-us-item">
                      <div className="choose-us-img">
                      <Link to="./bankingFinancialInsurance">
                          <img src="img/Banking.jpg" alt="" />
                        
                        <div className="choose-us-content">
                          <h3 className="title">
                            Banking, Financial & Insurance
                          </h3>
                          <p>
                            The banking, financial, and insurance sectors are
                            facing unprecedented change as they navigate digital
                            transformation, regulatory compliance, and evolving
                            customer expectations.
                          </p>
                        </div>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div ref={prevRef} className="swiper-button-next"></div>
                <div ref={nextRef} className="swiper-button-prev"></div>
              </div>
            </div>
          </div>
          {/* <!-- Choose Us Wrap End --> */}
        </div>
      </div>
    </>
  );
}

export default HomeIndustriesSection;
