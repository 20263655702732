import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { ArrowUpRight } from "lucide-react";
import InnerPageBanner from "../../components/InnerPageBanner";
import { NavLink } from "react-router-dom";

function Seven83Blog() {
  const title = "Blog";
  const bannerImage = "img/leadershipBanner.jpg";

  const [newsUpdates, setNewsUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const removeHtmlTags = (htmlContent) => {
    return htmlContent.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = new Intl.DateTimeFormat("en-GB", { day: "2-digit" }).format(
      date
    );
    const month = new Intl.DateTimeFormat("en-GB", { month: "short" }).format(
      date
    );

    return { day, month };
  };

  useEffect(() => {
    const fetchNewsUpdates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/blogs/`
        );

        const cleanedData = response.data.map((item) => ({
          ...item,
          content: removeHtmlTags(item.content),
          created: formatDate(item.created),
        }));

        setNewsUpdates(cleanedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchNewsUpdates();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section blog-list-section">
        <div className="container">
          <div className="blog-list-wrapper">
            {newsUpdates.map((news, i) => (
              <div className="blog-card border--top" key={i}>
                <div className="row g-0 align-items-center">
                  <div className="col-lg-5">
                    <div className="blog-card-img-wrap">
                      <NavLink
                        className="card-img"
                        to={`/seven83_blog/${news.id}`}
                      >
                        <img src={news.image} alt={news.title} />
                      </NavLink>
                      <a className="date" href="/blog">
                        <span>
                          <strong>{news.created.day}</strong>
                          {news.created.month}
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="blog-card-content-wrap">
                      <div className="blog-card-content">
                        {/* <div className="blog-meta">
                          <ul className="category">
                            <li>
                              <a href="/">Development</a>
                            </li>
                          </ul>
                          <div className="blog-comment">
                            <span>Comment (20)</span>
                          </div>
                        </div> */}
                        <h4>
                          <NavLink to={`/seven83_blog/${news.id}`}>
                            {news.title}
                          </NavLink>
                        </h4>
                        <p>{news.content}</p>
                      </div>
                      <div className="button-area">
                        <NavLink
                          className="read-more-btn"
                          to={`/seven83_blog/${news.id}`}
                        >
                          Read More <ArrowUpRight />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Seven83Blog;
