import InnerPageBanner from "../../components/InnerPageBanner";

function Healthcare() {
  const title = "Healthcare";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  The healthcare industry is undergoing a major transformation,
                  driven by the need for better patient outcomes, cost
                  efficiency, and data security. At Seven83 Systems, we provide
                  healthcare organizations with the technology needed to improve
                  patient care, streamline operations, and comply with
                  regulatory requirements. Our services include healthcare
                  application development, data integration, AI-driven
                  analytics, and cloud solutions, all designed to enhance
                  healthcare delivery
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="whychoose-us-item h-100" data-aos="flip-left">
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Healthcare Expertise</a>
                      </h3>
                      <p>
                        We have a deep understanding of the healthcare
                        industry’s unique requirements and challenges.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="300"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Patient-Centric Solutions</a>
                      </h3>
                      <p>
                        Our technologies are designed to improve patient
                        experiences, from EHR systems to telemedicine platforms.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1200"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Compliance and Security</a>
                      </h3>
                      <p>
                        We ensure full compliance with healthcare regulations
                        such as HIPAA, protecting sensitive patient data.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="900"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Innovation in Care Delivery</a>
                      </h3>
                      <p>
                        We leverage AI, data analytics, and cloud technologies
                        to transform how healthcare providers deliver care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-key-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="section-title">
                <h2 className="title">
                  Key Solutions <br />
                  for Healthcare
                </h2>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="services-card" data-aos="fade-up">
                    <div className="icon">
                      <img src="img/icons/connection.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>EHR Integration</h3>
                        <span>Healthcare</span>
                      </div>
                      <p>
                        Seamlessly integrate electronic health record systems
                        for more efficient healthcare workflows.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <img src="img/icons/integration.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Telehealth & <br />
                          Remote Care
                        </h3>
                        <span>Healthcare</span>
                      </div>
                      <p>
                        Implement telemedicine solutions to improve patient
                        accessibility and care delivery.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="icon">
                      <img src="img/icons/security-system.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Healthcare
                          <br /> Data Security
                        </h3>
                        <span>Healthcare</span>
                      </div>
                      <p>
                        Ensure compliance with industry regulations and protect
                        patient data with advanced security solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="900"
                  >
                    <div className="icon">
                      <img src="img/icons/process.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          AI for
                          <br /> Healthcare
                        </h3>
                        <span>Healthcare</span>
                      </div>
                      <p>
                        Use data-driven insights to enhance patient outcomes,
                        optimize resources, and predict healthcare trends.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title">
                  <h2 className="title">
                    Explore
                    <br />
                    Healthcare
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6">
              <div className="services-item h-100" data-aos="fade-left">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">EHR Systems and Integration</a>
                  </h3>
                  <p>
                    Learn how we streamline healthcare operations with
                    integrated electronic health record systems.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/automation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Telemedicine Solutions</a>
                  </h3>
                  <p>
                    Discover how we help healthcare providers reach more
                    patients with our telemedicine platforms.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/ai.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="900"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">AI and Data Analytics in Healthcare</a>
                  </h3>
                  <p>
                    Explore how data-driven insights can improve patient
                    outcomes and operational efficiency.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/responsive.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="1200"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cloud for Healthcare</a>
                  </h3>
                  <p>
                    Understand how moving to the cloud can help healthcare
                    organizations enhance data security and reduce costs.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/startup.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Healthcare;
