import { Card } from "react-bootstrap";
import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast, Toaster } from "sonner";

function AddCategory() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const postData = {
      name: data.category,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/categories/`,
        postData
      );
      console.log("Form submitted successfully:", response.data);
      setValue("category", "");
      toast.success("Posting successfully completed.");
    } catch (error) {
      console.error(
        "Error submitting form:",
        error.response?.data || error.message
      );
      toast.error("Posting Error");
    }
  };

  return (
    <div className="section seven-about-section-03 section-padding">
      <div className="section-title text-center mt-3">
        <h2 className="title">Add Category</h2>
      </div>
      <div className="container pt-3">
        <Card className="col-sm-6 mx-auto">
          <div className="general-inquiry-page m-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="single-form">
                    <input
                      className="text-muted form-control"
                      type="text"
                      {...register("category", {
                        required: "Category is required",
                      })}
                      placeholder="Category *"
                    />
                    {errors.category && (
                      <span className="text-danger">
                        {errors.category.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12 pt-4">
                  <div className="form-btn d-flex">
                    <button className="btn rounded-0 ms-auto" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <Toaster position="bottom-right" richColors />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default AddCategory;
