import React from "react";
import { createRoot } from "react-dom/client";
import AOS from "aos";

import "bootstrap/dist/js/bootstrap.esm.min";
import "./styles.scss";
import App from "./app/App";
AOS.init();

const root = createRoot(document.getElementById("root"));

root.render(
  <div className="main-wrapper">
    <App />
  </div>
);
