import InnerPageBanner from "../../components/InnerPageBanner";

function ModernizeTransform() {
  const title = "Modernize & Transform";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  Legacy systems can often hinder growth, innovation, and
                  efficiency. At Seven83 Systems, our Modernize & Transform
                  services are designed to help businesses update their outdated
                  technology and embrace modern, scalable, and cloud-based
                  systems. We focus on transforming legacy infrastructure into
                  agile, efficient, and future-ready environments..
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="whychoose-us-item h-100" 
                  data-aos="flip-left"
                 >
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Legacy Modernization Experts</a>
                      </h3>
                      <p>
                        We specialize in modernizing outdated technology stacks,
                        transforming them into agile, scalable systems.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="whychoose-us-item h-100"
                  data-aos="flip-left"
                  data-aos-delay="300">
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Proven Methodology</a>
                      </h3>
                      <p>
                        Our approach ensures minimal disruption to your
                        operations while delivering optimal modernization
                        results.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="whychoose-us-item h-100"
                  data-aos="flip-left"
                  data-aos-delay="600">
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Cloud-Ready Solutions</a>
                      </h3>
                      <p>
                        We help businesses migrate to cloud-based platforms,
                        enhancing flexibility and scalability.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div className="whychoose-us-item h-100"
                  data-aos="flip-left"
                  data-aos-delay="900">
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Cost Efficiency</a>
                      </h3>
                      <p>
                        Modernizing your infrastructure reduces maintenance
                        costs and improves overall efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div className="whychoose-us-item h-100"
                  data-aos="flip-left"
                  data-aos-delay="1200">
                    <div className="whychoose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Innovation Enablement</a>
                      </h3>
                      <p>
                        We enable businesses to leverage new technologies, such
                        as AI, IoT, and cloud, as part of their modernization
                        strategy.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div className="whychoose-us-item h-100" 
                  data-aos="flip-left"
                  data-aos-delay="1500">
                    <div className="whychoose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Seamless Transition</a>
                      </h3>
                      <p>
                        We ensure smooth transitions from legacy systems to
                        modern platforms with minimal downtime.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title">
                  <h2 className="title">
                    Explore
                    <br />
                    Modernize & Transform     
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="services-item h-100">
                <div className="services-item-content"   data-aos="fade-left"
             >
                  <h3 className="title">
                    <a href="#">Legacy Modernization Roadmap</a>
                  </h3>
                  <p>
                    Learn about the step-by-step process we use to modernize
                    your systems.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/automation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="services-item h-100"   data-aos="fade-left"
               data-aos-delay="300">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cloud Migration</a>
                  </h3>
                  <p>
                    Explore how we help businesses migrate their legacy systems
                    to cloud platforms.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/success-story.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div className="services-item h-100"   data-aos="fade-left"
               data-aos-delay="600">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Digital Transformation Strategy</a>
                  </h3>
                  <p>
                    Discover how modernization and transformation fit into a
                    broader digital strategy.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/ai.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div className="services-item h-100"   data-aos="fade-left"
               data-aos-delay="900">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Agility in Action</a>
                  </h3>
                  <p>
                    Read how modernization leads to faster time-to-market and
                    greater innovation in products and services.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/responsive.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div className="services-item h-100"   data-aos="fade-left"
               data-aos-delay="1200">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Success Stories</a>
                  </h3>
                  <p>
                    See how businesses have modernized their technology stack
                    with our help and unlocked new growth opportunities.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/startup.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModernizeTransform;
