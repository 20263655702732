import InnerPageBanner from "../components/InnerPageBanner";

function AboutUs() {
  const title = "About Us";
  const bannerImage = "img/aboutUsBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title" data-aos="fade-up">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text" data-aos="fade-up">
                  Seven83 Systems draws inspiration from Earth’s heartbeat, the
                  7.83 Hz frequency, also known as the Schumann Resonance, a
                  natural electromagnetic pulse resonating across the planet.
                  This rhythm symbolizes balance, stability, and connection
                  values we deeply integrate into our services and solutions. At
                  Seven83 Systems, we believe in harmonizing cutting-edge
                  technology with human-centered design to create sustainable
                  and impactful digital solutions.
                </p>
                <div className="about-list-03">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="about-list-item-03" data-aos="fade-left">
                        <h3 className="title text-primary">Our Mission</h3>
                        <p>
                          Help businesses of all sizes embrace the power of
                          digital transformation.
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="about-list-item-03" data-aos="fade-right">
                        <h3 className="title text-primary">Our Vision</h3>
                        <p className="con">
                          Learn more about how we bring balance and innovation
                          to the forefront of every project.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-choose-us-section section-padding">
        <div className="container">
          <div className="choose-us-wrap">
            <div className="choose-us-content-wrap">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
                  <div className="section-title">
                    <h2 className="title">
                      Explore <br />
                      in Seven83 Systems.
                    </h2>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="choose-us-item-02 h-100"
                    data-aos="flip-right"
                  >
                    <div className="choose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title">
                        <a href="#">Meet the Team</a>
                      </h3>
                      <p>
                        Discover the experts behind Seven83 Systems who are
                        committed to your success.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="choose-us-item-02 h-100"
                    data-aos="flip-right"
                    data-aos-delay="300"
                  >
                    <div className="choose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title">
                        <a href="#">Our Values</a>
                      </h3>
                      <p>
                        Understand the core values that guide our work, from
                        transparency to sustainability.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div
                    className="choose-us-item-02 h-100"
                    data-aos="flip-right"
                    data-aos-delay="600"
                  >
                    <div className="choose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title">
                        <a href="#">Careers</a>
                      </h3>
                      <p>
                        Join us in building cutting-edge solutions. Explore
                        career opportunities at Seven83 Systems.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6  mt-4">
                  <div
                    className="choose-us-item-02 h-100"
                    data-aos="flip-right"
                    data-aos-delay="900"
                  >
                    <div className="choose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title">
                        <a href="#">Our Impact</a>
                      </h3>
                      <p>
                        See how our innovative solutions have transformed
                        businesses across various sectors.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
                  <div
                    className="choose-us-item-02 h-100"
                    data-aos="flip-right"
                    data-aos-delay="1200"
                  >
                    <div className="choose-us-img">
                      <img src="img/icons/community.png" alt="" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title">
                        <a href="#">Community Involvement</a>
                      </h3>
                      <p>
                        Learn about our efforts to give back and make a positive
                        social impact.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-team-section seven-team-section-03 seven-team-section-02 section-padding">
        <div className="container">
          <div className="team-wrap">
            <div className="section-title mb-5">
              <h2 className="title">Leadership Team</h2>
              <p className="contnet mt-3">
                Our leadership team brings a wealth of experience and expertise
                across various industries, guiding our strategic direction and
                ensuring the delivery <br /> of exceptional results for our
                clients.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="single-team" data-aos="fade-up">
                  <div className="team-img">
                    <a href="team.html">
                      <img src="img/team/SantoshAkkula.jpg" alt="" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h3 className="name">
                      <a href="#">
                        Santosh <br /> Akkula
                      </a>
                    </h3>
                    <span className="designation">Director & CEO</span>
                    <div className="team-social">
                      <ul className="social">
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-team" data-aos="fade-up">
                  <div className="team-img">
                    <a href="team.html">
                      <img src="img/team/TvRamaGopal.jpg" alt="" />
                    </a>
                  </div>
                  <div className="team-content">
                    <h3 className="name">
                      <a href="#">
                        T.V. <br /> Ramagopal
                      </a>
                    </h3>
                    <span className="designation">Director & CTO</span>
                    <div className="team-social">
                      <ul className="social">
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
