import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import MainNavbar from "../components/MainNavbar";
import AdminMainNavbar from "../components/AdminMainNavbar";

function MainLayout() {
  const { pathname } = useLocation();
  const adminPaths = ["/admin", "/addCategory", "/postJob", "/addPost"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      {!adminPaths.includes(location.pathname) && <MainNavbar />}
      {adminPaths.includes(location.pathname) && <AdminMainNavbar />}
      <Outlet />
      <Footer />
    </>
  );
}

export default MainLayout;
