import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function HomeHeroSection() {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1)}s`;
  };

  return (
    <>
      {/* <!-- Hero Start --> */}
      <div className="section seven-hero-section-03 d-flex align-items-center position-relative">
        {/* <!-- Swiper --> */}
        <div className="heroBanner">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            className="swiper-wrapper"
          >
            {/* <div className="swiper-wrapper"> */}
            <div className="shape-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="726.5px"
                height="726.5px"
              >
                <path
                  fillRule="evenodd"
                  stroke="rgb(255, 255, 255)"
                  strokeWidth="1px"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  opacity="0.302"
                  fill="none"
                  d="M28.14,285.269 L325.37,21.216 C358.860,-8.852 410.655,-5.808 440.723,28.14 L704.777,325.37 C734.846,358.860 731.802,410.654 697.979,440.723 L400.956,704.777 C367.133,734.845 315.338,731.802 285.269,697.979 L21.216,400.955 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z"
                />
              </svg>
            </div>
            <div className="shape-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="972.5px"
                height="970.5px"
              >
                <path
                  fillRule="evenodd"
                  stroke="rgb(255, 255, 255)"
                  strokeWidth="1px"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  opacity="0.5"
                  fill="none"
                  d="M38.245,381.103 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.746 L29.158,535.733 C-11.32,490.524 -6.963,421.293 38.245,381.103 Z"
                />
              </svg>
            </div>
            <SwiperSlide className="swiper-slide">
              <div className="bg-secondary w-100 h-100 d-flex align-items-center">
                {/* <!-- Slide 1 --> */}
                <div className="swiper-slide-img">
                  <img src="img/DigitalBanner.jpg" alt="" />
                </div>
                <div className="container-fluid px-5 mx-5">
                  <div className="row align-items-center h-100">
                    <div className="col-lg-6 col-md-6">
                      {/* <!-- Hero Content Start --> */}
                      <div className="hero-content text-left">
                        <h2
                          className="title"
                          data-aos="fade-up"
                          data-aos-delay="800"
                        >
                          Elevate Operations with <br />
                          Seamless ServiceNow
                        </h2>

                        <div
                          className="hero-btn"
                          data-aos="fade-up"
                          data-aos-delay="1000"
                        >
                          <NavLink
                            className="btn rounded-0 btn-lg text-secondary px-4"
                            to="/serviceNow"
                          >
                            Read more
                            <i className="ms-2" data-lucide="move-right"></i>
                          </NavLink>
                        </div>
                      </div>
                      {/* <!-- Hero Content End --> */}
                    </div>
                    <div className="col-lg-5 ms-auto"></div>
                  </div>
                </div>
                {/* <!-- Slide 1 --> */}
              </div>
            </SwiperSlide>
            {/* <!-- Slide 1 --> */}

            {/* <!-- Slide 2 --> */}
            <SwiperSlide className="swiper-slide">
              <div className="w-100 h-100 d-flex align-items-center">
                <div className="swiper-slide-img">
                  <img src="img/DataAiBanner.jpg" alt="" />
                </div>
                <div className="container-fluid px-5 mx-5">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                      {/* <!-- Hero Content Start --> */}
                      <div className="hero-content text-left">
                        <h2
                          className="title"
                          data-aos="fade-up"
                          data-aos-delay="800"
                        >
                          Unlock Business <br />
                          Intelligence with Data & AI
                        </h2>

                        <div
                          className="hero-btn"
                          data-aos="fade-up"
                          data-aos-delay="1000"
                        >
                          <NavLink
                            className="btn rounded-0 btn-lg text-secondary px-4"
                            to="/serviceNow"
                          >
                            Read more
                            <i className="ms-2" data-lucide="move-right"></i>
                          </NavLink>
                        </div>
                      </div>
                      {/* <!-- Hero Content End --> */}
                    </div>
                    <div className="col-lg-5 ms-auto"></div>
                  </div>
                </div>
              </div>
              {/* <!-- Slide 2 --> */}
            </SwiperSlide>
            {/* <!-- Slide 3 --> */}
            <SwiperSlide className="swiper-slide">
              <div className="w-100 h-100 d-flex align-items-center">
                <div className="swiper-slide-img">
                  <img src="img/ModernizeBanner.jpg" alt="" />
                </div>
                <div className="container-fluid px-5 mx-5">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                      {/* <!-- Hero Content Start --> */}
                      <div className="hero-content text-left">
                        <h2
                          className="title"
                          data-aos="fade-up"
                          data-aos-delay="800"
                        >
                          Modernize Your Business, <br />
                          Transform Your Future
                        </h2>

                        <div
                          className="hero-btn"
                          data-aos="fade-up"
                          data-aos-delay="1000"
                        >
                          <NavLink
                            className="btn rounded-0 btn-lg text-secondary px-4"
                            to="/serviceNow"
                          >
                            Read more
                            <i className="ms-2" data-lucide="move-right"></i>
                          </NavLink>
                        </div>
                      </div>
                      {/* <!-- Hero Content End --> */}
                    </div>
                    <div className="col-lg-5 ms-auto"></div>
                  </div>
                </div>
              </div>
              {/* <!-- Slide 2 --> */}
            </SwiperSlide>

            {/* <!-- Slide 4 --> */}
            <SwiperSlide className="swiper-slide">
              <div className="w-100 h-100 d-flex align-items-center">
                <div className="swiper-slide-img">
                  <img src="img/DevelopmentBanner.jpg" alt="" />
                </div>
                <div className="container-fluid px-5 mx-5">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                      {/* <!-- Hero Content Start --> */}
                      <div className="hero-content text-left">
                        <h2
                          className="title"
                          data-aos="fade-up"
                          data-aos-delay="800"
                        >
                          Custom App Development <br />
                          for Unique Business Needs
                        </h2>

                        <div
                          className="hero-btn"
                          data-aos="fade-up"
                          data-aos-delay="1000"
                        >
                          <NavLink
                            className="btn rounded-0 btn-lg text-secondary px-4"
                            to="/serviceNow"
                          >
                            Read more
                            <i className="ms-2" data-lucide="move-right"></i>
                          </NavLink>
                        </div>
                      </div>
                      {/* <!-- Hero Content End --> */}
                    </div>
                    <div className="col-lg-5 ms-auto">
                      <div data-aos="zoom-in-up" data-aos-delay="1000"></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Slide 2 --> */}
            </SwiperSlide>

            <div className="autoplay-progress" slot="container-end">
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                <circle cx="24" cy="24" r="20"></circle>
              </svg>
              <span className="d-none" ref={progressContent}></span>
            </div>
          </Swiper>

          {/* <div className="swiper-pagination"></div>
          <div className="autoplay-progress">
            <svg viewBox="0 0 48 48">
              <circle cx="24" cy="24" r="14"></circle>
            </svg>
            <span className="d-none"></span>
          </div> */}
        </div>
      </div>
      {/* <!-- Hero End --> */}
    </>
  );
}

export default HomeHeroSection;
