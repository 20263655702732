import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { NavLink } from "react-router-dom";
import { CalendarClock, BookOpenCheck } from "lucide-react";

function HomeNewsUpdatesSection() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [newsUpdates, setNewsUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const removeHtmlTags = (htmlContent) => {
    return htmlContent.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Using Intl.DateTimeFormat to format the date
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);

    return formattedDate.replace(/ /g, " ").replace(",", ","); // To ensure format is '07 Oct, 2024'
  };

  useEffect(() => {
    const fetchNewsUpdates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/blogs/`
        );

        const cleanedData = response.data.map((item) => ({
          ...item,
          content: removeHtmlTags(item.content),
          created: formatDate(item.created),
          categories: item.categories.map((category) => category.name),
        }));

        setNewsUpdates(cleanedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchNewsUpdates();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <div
        className="section home-blog-section section-padding"
        style={{ backgroundImage: "url(img/bg/testi-bg4.jpg)" }}
      >
        <div className="container">
          <div className="section-title text-center">
            <h2 className="title">News and Updates </h2>
          </div>
        </div>
        {/* <!-- Case Study Content Wrap Start --> */}
        <div className="home-blog-section-wrap">
          <div className="swiper-container">
            <Swiper
              slidesPerView={3}
              spaceBetween={20}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              centeredSlides={true}
              loop={true}
              pagination={{
                el: ".case-study-active .swiper-pagination",
                clickable: true,
              }}
              modules={[Pagination]}
              className="swiper-wrapper"
            >
              {newsUpdates.map((news) => (
                <SwiperSlide className="swiper-slide" key={news.id}>
                  <div className="blog-card">
                    <div className="blog-card-img-wrap">
                      <NavLink
                        className="card-img"
                        to={`/seven83_blog/${news.id}`}
                      >
                        <img src={news.image} alt={news.title} />
                      </NavLink>
                      <span className="date">
                        <CalendarClock size="18px" className="me-2" />
                        {news.created}
                      </span>
                    </div>
                    <div className="card-content">
                      <div className="blog-meta">
                        <ul className="category">
                          <li>
                            <BookOpenCheck size="18px" className="me-2" />
                            {news?.categories && news.categories.length > 0 && (
                              <>
                                {news.categories.map((category, index) => (
                                  <span className="tag" key={index}>
                                    {category}
                                  </span>
                                ))}
                              </>
                            )}
                          </li>
                        </ul>
                      </div>
                      <h4>
                        <a href={`/seven83_blog/${news.id}`}>
                          <span>{news.title}</span>
                        </a>
                      </h4>
                    </div>
                  </div>
                  {/* <!-- Single Case Study Start --> */}
                  {/* <div className="single-case-study-02">
                    <div className="case-study-img">
                      <NavLink to={`/seven83_blog/${news.id}`}>
                        <img src={news.image} alt={news.title} />
                      </NavLink>
                    </div>
                    <div className="case-study-content">
                      <p className="sub-title">News</p>
                      <h3 className="title">
                        <a href={`/seven83_blog/${news.id}`}>
                          <span>{news.title}</span>
                        </a>
                      </h3>
                    </div>
                  </div> */}
                  {/* <!-- Single Case Study End --> */}
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <!-- Add Pagination --> */}
            <div className="swiper-pagination"></div>
            <div className="d-flex">
              <NavLink
                className="btn rounded-0 btn-lg mx-auto text-secondary py-3 px-4 mt-4"
                to="/seven83_blog"
              >
                View All
                <i className="ms-2" data-lucide="move-right"></i>
              </NavLink>
            </div>
          </div>
        </div>
        {/* <!-- Case Study Content Wrap End --> */}
      </div>
    </>
  );
}

export default HomeNewsUpdatesSection;
