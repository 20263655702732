import { Card } from "react-bootstrap";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast, Toaster } from "sonner";
import InnerPageBanner from "../components/InnerPageBanner";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function ApplyJob() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  const title = "Apply Form";
  const bannerImage = "img/leadershipBanner.jpg";
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [resume, setResume] = useState(null);
  const location = useLocation();
  const { jobId, jobTitle } = location.state || {};
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("current_salary", data.current_salary);
    formData.append("expected_salary", data.expected_salary);
    formData.append("location", data.location);
    formData.append("phone_number", data.phone_number);
    formData.append("message", data.message);
    formData.append("resume", resume);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/careers/${jobId}/application/`,
        formData
      );
      console.log("Form submitted successfully:", response.data);
      toast.success("Form Submitted successful!");
    } catch (error) {
      console.error(
        "Error submitting form:",
        error.response?.data || error.message
      );
      toast.error("Form Submitted failed.");
    }
    reset(); 
  };
  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section bg-white  section-padding">
        <div className="container">
          <div className="contact-wrap col-sm-8  mx-auto h-100 mx-6 px-4">
            <div className="contact-form h-100 pb-3">
              <h1 className="border-bottom fw-medium">{jobTitle}</h1>
            </div>
            <p className="pb-1">
              Fields marked with an
              <span className="text-danger">*</span> are required
            </p>
          </div>
          <div className="col-sm-8 mx-auto rounded-0">
            <div className="mx-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="single-form">
                      <input
                        type="text"
                        placeholder="First name *"
                        {...register("first_name", {
                          required: "First name is required",
                        })}
                      />
                      {errors.first_name && (
                        <p className="text-danger">
                          {errors.first_name.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="single-form">
                      <input
                        type="text"
                        placeholder="Last name *"
                        {...register("last_name", {
                          required: "Last name is required",
                        })}
                      />
                      {errors.last_name && (
                        <p className="text-danger">
                          {errors.last_name.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="single-form">
                      <input
                        type="email"
                        placeholder="Email *"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="text-danger">{errors.email.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="single-form">
                      <input
                        type="number"
                        placeholder="Phone number *"
                        {...register("phone_number", {
                          required: "Phone number is required",
                        })}
                      />
                      {errors.phone_number && (
                        <p className="text-danger">
                          {errors.phone_number.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="single-form">
                      <input
                        type="number"
                        placeholder="Current salary *"
                        {...register("current_salary", {
                          required: "Current salary is required",
                        })}
                      />
                      {errors.current_salary && (
                        <p className="text-danger">
                          {errors.current_salary.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="single-form">
                      <input
                        type="number"
                        placeholder="Expected salary *"
                        {...register("expected_salary", {
                          required: "Expected salary is required",
                        })}
                      />
                      {errors.expected_salary && (
                        <p className="text-danger">
                          {errors.expected_salary.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="single-form">
                      <input
                        type="text"
                        placeholder="Location *"
                        {...register("location", {
                          required: "Location is required",
                        })}
                      />
                      {errors.location && (
                        <p className="text-danger">{errors.location.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="single-form">
                      <input
                        className="form-control form-control-lg rounded-1 text-muted"
                        id="formFileLg"
                        type="file"
                        accept="*.*"
                        {...register("resume", {
                          required: "Resume is required",
                          validate: {
                            requiredFile: (value) =>
                              value.length > 0 || "Please upload a resume.",
                          },
                        })}
                        onChange={(e) => setResume(e.target.files[0])}
                      />
                      {errors.resume && (
                        <span className="text-danger">
                          {errors.resume.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="single-form">
                      <textarea
                        placeholder="Write a message *"
                        {...register("message", {
                          required: "Message is required",
                        })}
                      ></textarea>
                      {errors.message && (
                        <p className="text-danger">{errors.message.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 pt-3">
                    <div class=" form-btn d-flex flex-row-reverse bd-highlight gap-3">
                      <div class="ps-2 pt-2 bd-highlight">
                        <button
                          className="btn rounded-0 ms-auto applyButton"
                          type="submit"
                        >
                          Apply
                        </button>
                      </div>
                      <div class="p-2 bd-highlight">
                        <button
                          type="button"
                          className="btn applyButton btn-secondary rounded-0"
                          onClick={() =>
                            reset({
                              first_name: null,
                              last_name: null,
                              email: null,
                              phone_number: null,
                              current_salary: null,
                              expected_salary: null,
                              location: null,
                              resume: null,
                              message: null,
                            })
                          }
                        >
                          Reset
                        </button>
                      </div>
                      <div class="p-2 bd-highlight">
                        <button
                          className="btn btn-outline-secondary  applyButton bg-transparent border-1 rounded-0"
                          onClick={() => goBack()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="d-flex flex-row-reverse"></div>
                  </div>
                </div>
              </form>
              <Toaster position="bottom-right" richColors />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyJob;
