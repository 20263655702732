import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ChevronDown, Search } from "lucide-react";

const menuItems = [
  {
    label: "Home",
    url: "/",
    subMenu: [],
  },
  {
    label: "Company",
    url: "#",
    subMenu: [
      { label: "About Us", url: "/aboutUs" },
      { label: "Contact Us", url: "/contactUs" },
    ],
  },
  {
    label: "Services",
    url: "#",
    subMenu: [
      { label: "ServiceNow", url: "/serviceNow" },
      { label: "Data & AI", url: "/dataAI" },
      { label: "Cloud", url: "/cloud" },
      { label: "Consulting", url: "/consulting" },
      {
        label: "Enterprise Integration",
        url: "/enterpriseIntegration",
      },
      { label: "Modernize & Transform", url: "/modernizeTransform" },
      { label: "Microsoft Dynamics 365", url: "/microsoftDynamics" },
      { label: "App Development", url: "/appDevelopment" },
    ],
  },
  {
    label: "Industries",
    url: "#",
    subMenu: [
      { label: "Government", url: "/government" },
      { label: "Healthcare", url: "/healthcare" },
      { label: "Logistics", url: "/logistics" },
      { label: "Manufacturing", url: "/manufacturing" },
      { label: "Telecommunications", url: "/telecommunications" },
      {
        label: "Banking, Financial & Insurance",
        url: "/bankingFinancialInsurance",
      },
    ],
  },
  {
    label: "Careers",
    url: "/careers",
    subMenu: [],
  },
];

function MainNavbar() {
  window.onscroll = function () {
    const left = document.getElementById("header");

    if (left.scrollTop > 50 || self.pageYOffset > 50) {
      left.classList.add("sticky");
    } else {
      left.classList.remove("sticky");
    }
  };

  // Slide down animation for submenu
  function slideDown(element) {
    element.style.maxHeight = "360px";
    element.style.transition = "max-height 0.3s ease-in-out";
    element.style.overflow = "hidden";
    element.style.display = "block";
  }

  // Slide up animation for submenu
  function slideUp(element) {
    element.style.maxHeight = "0px";
    element.style.transition = "max-height 0.3s ease-in-out";
    element.style.overflow = "hidden";
    element.style.display = "none";
  }

  function getSiblings(e) {
    var t = [];
    let s = e.parentNode.firstChild;
    for (; s; ) 1 === s.nodeType && s !== e && t.push(s), (s = s.nextSibling);
    return t;
  }

  const offCanvasMenu = function (e) {
    e = document.querySelector(e);
    e.querySelectorAll(".sub-menu").forEach(function (e) {
      var t = document.createElement("span");
      t.classList.add("menu-expand"),
        e.parentElement.insertBefore(t, e),
        e.classList.contains("mega-menu") &&
          (e.classList.remove("mega-menu"),
          e.querySelectorAll("ul").forEach(function (e) {
            e.classList.add("sub-menu");
            var t = document.createElement("span");
            t.classList.add("menu-expand"),
              (t.innerHTML = "+"),
              e.parentElement.insertBefore(t, e);
          }));
    }),
      e.querySelectorAll(".menu-expand").forEach(function (e) {
        e.addEventListener("click", function (e) {
          e.preventDefault();
          e = this.parentElement;
          if (e.classList.contains("active")) {
            e.classList.remove("active");
            e.querySelectorAll(".sub-menu").forEach(function (submenu) {
              submenu.parentElement.classList.remove("active");
              slideUp(submenu);
            });
          } else {
            e.classList.add("active");
            slideDown(this.nextElementSibling);
            getSiblings(e).forEach(function (sibling) {
              sibling.classList.remove("active");
              sibling.querySelectorAll(".sub-menu").forEach(function (submenu) {
                submenu.parentElement.classList.remove("active");
                slideUp(submenu);
              });
            });
          }
        });
      });
  };

  useEffect(() => {
    offCanvasMenu(".offcanvas-menu");
  }, []);
  return (
    <>
      {/* <!-- Header Start  --> */}
      <div id="header" className="section header-section header-section-menu">
        <div className="container-fluid px-lg-5">
          {/* <!-- Header Wrap Start  --> */}
          <div className="header-wrap">
            <div className="header-logo">
              <a className="logo-white" href="/">
                <img src="/img/Seven83_white_logo.svg" alt="" />
              </a>
            </div>

            <div className="header-menu d-none d-lg-block ms-auto">
              <ul className="main-menu">
                {menuItems.map((menuItem, index) => (
                  <li
                    key={index}
                    className={menuItem.subMenu.length ? "has-submenu" : ""}
                  >
                    <NavLink className="nav-link" to={menuItem.url}>
                      {menuItem.label}
                      {menuItem.subMenu.length > 0 && <ChevronDown size="22" />}
                    </NavLink>
                    {/* {menuItem.subMenu.length > 0 && ( */}
                    {menuItem.subMenu.length > 0 && (
                      <ul className="sub-menu">
                        {menuItem.subMenu.map((subMenuItem, subIndex) => (
                          <li key={subIndex}>
                            <NavLink className="nav-link" to={subMenuItem.url}>
                              {subMenuItem.label}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {/* <!-- Header Meta Start --> */}
            <div className="header-meta">
              {/* <!-- Header Search Start --> */}
              <div className="header-search">
                <a className="search-btn" href="#">
                  <Search className="flaticon-loupe" />
                </a>
                <div className="search-wrap">
                  <div className="search-inner">
                    <Search
                      id="search-close"
                      className="flaticon-close search-close"
                    />
                    <div className="search-cell">
                      <form action="#">
                        <div className="search-field-holder">
                          <input
                            className="main-search-input"
                            type="search"
                            placeholder="Search Your Keyword..."
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Header Search End --> */}

              {/* <!-- Header Toggle Start --> */}
              <div className="header-toggle d-lg-none">
                <button
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              {/* <!-- Header Toggle End --> */}
            </div>
            {/* <!-- Header Meta End  --> */}
          </div>
          {/* <!-- Header Wrap End  --> */}
        </div>
      </div>
      {/* <!-- Header End --> */}

      <div className="offcanvas offcanvas-start" id="offcanvasExample">
        <div className="offcanvas-header">
          <div className="offcanvas-logo">
            <a className="logo-white" href="../index.html">
              <img src="/img/Seven83_white_logo.svg" alt="" />
            </a>
          </div>
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="offcanvas"
          >
            <i className="flaticon-close"></i>
          </button>
        </div>

        <div className="offcanvas-body">
          <div className="offcanvas-menu">
            <ul className="main-menu">
              {menuItems.map((menuItem, index) => (
                <li
                  key={index}
                  className={menuItem.subMenu.length ? "has-submenu" : ""}
                >
                  <NavLink className="nav-link" to={menuItem.url}>
                    {menuItem.label}
                    {menuItem.subMenu.length > 0 && <ChevronDown size="22" />}
                  </NavLink>
                  {/* {menuItem.subMenu.length > 0 && ( */}
                  {menuItem.subMenu.length > 0 && (
                    <ul className="sub-menu">
                      {menuItem.subMenu.map((subMenuItem, subIndex) => (
                        <li key={subIndex}>
                          <NavLink className="nav-link" to={subMenuItem.url}>
                            {subMenuItem.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainNavbar;
