import InnerPageBanner from "../../components/InnerPageBanner";

function Manufacturing() {
  const title = "Manufacturing";
  const bannerImage = "img/leadershipBanner.jpg";

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section seven-about-section-03 section-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="about-content-03">
                <div className="section-title">
                  <h2 className="title">Overview</h2>
                </div>
                <p className="text">
                  Manufacturing is evolving rapidly with the rise of Industry
                  4.0, which brings automation, IoT, and advanced analytics into
                  the production process. At Seven83 Systems, we provide
                  technology solutions that help manufacturers modernize their
                  operations, improve efficiency, and reduce costs. Our services
                  include automation systems, IoT integration, cloud-based ERP
                  solutions, and AI-driven analytics, all designed to optimize
                  production and streamline operations.
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-img-warp-3">
                <div className="shape-1"></div>
                <div className="about-img about-img-big">
                  <img src="img/icons/information-board.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-whychoose-us-section section-padding">
        <div className="container">
          <div className="whychoose-us-wrap">
            <div className="section-title">
              <h2 className="title">Why Choose Us</h2>
            </div>
            <div className="whychoose-us-content-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="whychoose-us-item h-100" data-aos="flip-left">
                    <div className="whychoose-us-img">
                      <img src="img/icons/team.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Industry 4.0 Expertise</a>
                      </h3>
                      <p>
                        We help manufacturers adopt Industry 4.0 technologies,
                        including automation and IoT, to modernize their
                        operations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="300"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/values.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Efficiency Optimization</a>
                      </h3>
                      <p>
                        Our solutions streamline production processes, reduce
                        downtime, and improve overall efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="1200"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/impact.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Data-Driven Insights</a>
                      </h3>
                      <p>
                        We use AI and analytics to provide actionable insights
                        that drive better decision-making in manufacturing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mt-4">
                  <div
                    className="whychoose-us-item h-100"
                    data-aos="flip-left"
                    data-aos-delay="900"
                  >
                    <div className="whychoose-us-img">
                      <img src="img/icons/goal.png" alt="" />
                    </div>
                    <div className="whychoose-us-content">
                      <h3 className="title">
                        <a href="#">Custom Solutions</a>
                      </h3>
                      <p>
                        We tailor our solutions to meet the specific needs of
                        your manufacturing environment, whether it’s automating
                        a production line or integrating ERP systems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-key-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="section-title">
                <h2 className="title">
                  Key Solutions <br />
                  for Manufacturing
                </h2>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="row g-0">
                <div className="col-md-6">
                  <div className="services-card" data-aos="fade-up">
                    <div className="icon">
                      <img src="img/icons/connection.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Smart Manufacturing <br /> Systems
                        </h3>
                        <span>Manufacturing</span>
                      </div>
                      <p>
                        Implement automation and IoT solutions to enhance
                        production efficiency and reduce downtime.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="icon">
                      <img src="img/icons/integration.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          AI-Driven Quality <br /> Control
                        </h3>
                        <span>Manufacturing</span>
                      </div>
                      <p>
                        Use AI-powered analytics to improve product quality and
                        identify potential production issues.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="icon">
                      <img src="img/icons/security-system.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>Cloud-Based ERP Integration</h3>
                        <span>Manufacturing</span>
                      </div>
                      <p>
                        Integrate cloud-based ERP systems to streamline
                        operations, enhance collaboration, and manage resources
                        effectively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="services-card border-bottom-0"
                    data-aos="fade-up"
                    data-aos-delay="900"
                  >
                    <div className="icon">
                      <img src="img/icons/process.png" alt="" />
                    </div>
                    <div className="services-content">
                      <div className="title-category">
                        <h3>
                          Predictive <br /> Maintenance
                        </h3>
                        <span>Manufacturing</span>
                      </div>
                      <p>
                        Use data analytics to predict equipment failures and
                        reduce unplanned downtime.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section seven-services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 d-flex align-items-center">
              <div className="services-item services-item-bg">
                <div className="section-title">
                  <h2 className="title">
                    Explore
                    <br />
                    Manufacturings
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6">
              <div className="services-item h-100" data-aos="fade-left">
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Automation in Manufacturing</a>
                  </h3>
                  <p>
                    Discover how we help manufacturers automate production
                    processes to improve efficiency and reduce costs .
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/automation.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="600"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#"> IoT Integration</a>
                  </h3>
                  <p>
                    Learn how IoT devices can provide real-time data and improve
                    visibility into your manufacturing operations.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/ai.png" alt="" />
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="900"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">AI-Driven Analytics for Manufacturing</a>
                  </h3>
                  <p>
                    Explore how data analytics can help manufacturers improve
                    product quality and optimize production.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/responsive.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mt-4">
              <div
                className="services-item h-100"
                data-aos="fade-left"
                data-aos-delay="1200"
              >
                <div className="services-item-content">
                  <h3 className="title">
                    <a href="#">Cloud ERP Solutions</a>
                  </h3>
                  <p>
                    Understand how cloud-based ERP systems enhance collaboration
                    and streamline manufacturing processes.
                  </p>
                </div>
                <div className="services-item-img">
                  <img src="img/icons/startup.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Manufacturing;
