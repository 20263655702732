import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import InnerPageBanner from "../../components/InnerPageBanner";
import {
  MessageCircle,
  CalendarClock,
  BookOpenCheck,
  ArrowLeft,
} from "lucide-react";
import { NavLink } from "react-router-dom";
const BlogDetails = () => {
  const title = "Blog";
  const bannerImage = "../img/leadershipBanner.jpg";

  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
    return formattedDate.replace(/ /g, " ").replace(",", ",");
  };

  useEffect(() => {
    if (id) {
      const fetchBlogDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/blogs/${id}/`
          );
          const blogData = {
            ...response.data,
            categories: response.data.categories.map(
              (category) => category.name
            ),
          };
          formatDate(response.data.created);
          setBlog(blogData);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      fetchBlogDetails();
    }
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!blog) return <div>No blog found</div>;

  return (
    <>
      <InnerPageBanner image={bannerImage} title={title} />
      <div className="section blog-details-section">
        <div className="container">
          <div className="blog-details-wrap px-lg-5 mb-5">
            <div className="d-flex justify-content-end">
              <NavLink
                className="btn btn-primary rounded-0 btn-lg text-secondary py-2 px-4 mb-4 ms-auto"
                to="/seven83_blog"
              >
                <ArrowLeft size="22" className="ms-2" /> Back to Blogs
              </NavLink>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="blog-details-post">
                  <div className="blog-image">
                    <div className="batch">
                      {blog?.categories && blog.categories.length > 0 && (
                        <>
                          {blog.categories.map((category, index) => (
                            <span className="tag" key={index}>
                              {category}
                            </span>
                          ))}
                        </>
                      )}
                    </div>
                    <img src={blog.image} alt="" />
                  </div>
                  <div className="blog-details-author-meta">
                    <div>
                      <MessageCircle size="18px" className="me-2" />0 Comments
                    </div>
                    <ul>
                      <li>
                        <BookOpenCheck size="18px" className="me-2" />
                        {blog?.categories && blog.categories.length > 0 && (
                          <>
                            {blog.categories.map((category, index) => (
                              <span className="tag" key={index}>
                                {category}
                              </span>
                            ))}
                          </>
                        )}
                      </li>
                      <li>
                        <CalendarClock size="18px" className="me-2" />
                        {blog?.created ? formatDate(blog.created) : ""}
                      </li>
                    </ul>
                  </div>
                  <div className="blog-content">
                    {/* <h3 className="title">{blog.title}</h3> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blog?.content || "",
                      }}
                      className="blog-content-text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
