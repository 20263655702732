import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast, Toaster } from "sonner";

function SalesInquiryForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      enquiry_type: "Sales Enquiry",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contacts/`,
        payload
      );
      toast.success(
        "Thank you for your Sales inquiry! We'll get back to you shortly."
      );
      reset();
    } catch (error) {
      toast.error("There was an error submitting the form", error);
    }
  };

  return (
    <div className="general-inquiry-page">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm-6">
            <div className="single-form">
              <input
                type="text"
                placeholder="Name *"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <p className="text-danger">{errors.name.message}</p>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="single-form">
              <input
                type="email"
                placeholder="Email *"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && (
                <p className="text-danger">{errors.email.message}</p>
              )}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="single-form">
              <input
                type="text"
                placeholder="Product Name *"
                {...register("subject", {
                  required: "Product Name is required",
                })}
              />
              {errors.subject && (
                <p className="text-danger">{errors.subject.message}</p>
              )}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="single-form">
              <input
                type="text"
                placeholder="Company *"
                {...register("institution_name", {
                  required: "Company is required",
                })}
              />
              {errors.institution_name && (
                <p className="text-danger">{errors.institution_name.message}</p>
              )}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="single-form">
              <textarea
                placeholder="Write a message *"
                {...register("message", { required: "Message is required" })}
              ></textarea>
              {errors.message && (
                <p className="text-danger">{errors.message.message}</p>
              )}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-btn d-flex">
              <button className="btn rounded-0 ms-auto" type="submit">
                Submit Sales Inquiry
              </button>
            </div>
          </div>
        </div>
      </form>

      <Toaster position="bottom-right" richColors />
    </div>
  );
}

export default SalesInquiryForm;
