import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast, Toaster } from "sonner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Card } from "react-bootstrap";

function AddPost() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/categories/`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("categories", data.categories);
    formData.append("content", data.content);
    formData.append("image", image);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/blogs/`,
        formData
      );
      console.log("Form submitted successfully:", response);
      toast.success("Posting successfully completed.");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Posting Error");
    }
  };

  return (
    <div className="section seven-about-section-03 section-padding">
      <div className="section-title text-center mt-3">
        <h2 className="title">Add Post</h2>
      </div>
      <div className="container pt-3">
        <Card className="col-sm-6 mx-auto">
          <div className="general-inquiry-page m-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="single-form">
                    <input
                      className="text-muted"
                      type="text"
                      {...register("title", { required: "Title is required" })}
                      placeholder="Title *"
                    />
                    {errors.title && (
                      <span className="text-danger">
                        {errors.title.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="single-form">
                    <select
                      className="form-select text-muted"
                      {...register("categories", {
                        required: "Category is required",
                      })}
                    >
                      <option value="" disabled selected hidden>
                        Select Category
                      </option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                    {errors.categories && (
                      <span className="text-danger">
                        {errors.categories.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="single-form">
                    <input
                      className="form-control form-control-lg rounded-1 text-muted"
                      id="formFileLg"
                      type="file"
                      accept="image/*"
                      {...register("image", {
                        required: "Image is required",
                        validate: {
                          requiredFile: (value) =>
                            value.length > 0 || "Please upload an image file.",
                          fileType: (value) => {
                            const file = value[0];
                            return (
                              (file &&
                                [
                                  "image/jpeg",
                                  "image/png",
                                  "image/gif",
                                ].includes(file.type)) ||
                              "Only JPEG, PNG, or GIF files are allowed."
                            );
                          },
                        },
                      })}
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="single-form">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "blockQuote",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "insertTable",
                          "mediaEmbed",
                          "imageUpload",
                          "undo",
                          "redo",
                          "|",
                          "alignment:left",
                          "alignment:right",
                          "alignment:center",
                          "alignment:justify",
                        ],
                        image: {
                          toolbar: [
                            "imageTextAlternative",
                            "imageStyle:full",
                            "imageStyle:side",
                          ],
                          upload: {
                            types: ["jpeg", "png", "gif", "bmp", "webp"],
                          },
                        },
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue("content", data);
                      }}
                    />
                    {errors.content && (
                      <span className="text-danger">
                        {errors.content.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-sm-12 pt-4">
                  <div className="form-btn d-flex">
                    <button className="btn rounded-0 ms-auto" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <Toaster position="bottom-right" richColors />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default AddPost;
